import React from 'react';
import PropTypes from 'prop-types';
import NavBar from 'js/components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import ActionBanner from 'js/components/ActionBanner';
import Footer from 'cccisd-footer';
import Modal from 'cccisd-modal';
import style from './style.css';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { client } from 'cccisd-apollo';
import groupDeploymentQuery from '../../graphql/groupDeploymentQuery.graphql';

var AppDefs = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;
let deploymentHandle;
const isUber = ['uberadmin', 'guest'].includes(Fortress.user.acting.role.handle);
if (!isUber) {
    deploymentHandle = `${Fortress.user.acting.group.data_type}_${new Date().getFullYear()}`;
}

export default class FixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: style.wideContainer,
    };

    state = {
        deploymentProgress: null,
        showBanner: false,
    };

    componentDidMount = () => {
        if (!isUber) {
            this.checkComplete();
        }
    };

    checkComplete = async () => {
        await this.getGroupDeploymentProgress();
        this.setState({ showBanner: this.showBanner() });
    };

    showBanner = () => {
        if (isUber) {
            return false;
        }
        const hasManage = JSON.parse(Fortress.user.acting.role_data.permissions).includes('manage');

        if (!this.state.deploymentProgress && hasManage) {
            return true;
        }
        return false;
    };

    getGroupDeploymentProgress = async () => {
        if (['uberadmin', 'guest'].includes(Fortress.user.acting.role.handle)) {
            return false;
        }
        const response = await client.query({
            query: groupDeploymentQuery,
            variables: {
                deploymentHandle,
                handle: deploymentHandle,
                groupId: Fortress.user.acting.group.id,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        await this.setState({
            deploymentProgress: response.data.roles.metricspawn.assignmentProgress.completed,
            groupPawn: response.data.roles.metricspawn.pawn,
        });
    };

    getFlowPropsVars = () => {
        const {
            groupDescriptionQuestion,
            dateEstablishedQuestion,
            populationFocusQuestion,
            positionsQuestion,
            turnoverRateQuestion,
            caseloadSizeQuestion,
            supervisionQuestion,
            parentPeerSupportQuestion,
            youthPeerSupportQuestion,
            trainingGroupUsedQuestion,
        } = Fortress.user.acting.group.type_data;
        return {
            groupDescriptionQuestion: groupDescriptionQuestion ? '1' : null,
            dateEstablishedQuestion: dateEstablishedQuestion ? '1' : null,
            populationFocusQuestion: populationFocusQuestion ? '1' : null,
            positionsQuestion: positionsQuestion ? '1' : null,
            turnoverRateQuestion: turnoverRateQuestion ? '1' : null,
            caseloadSizeQuestion: caseloadSizeQuestion ? '1' : null,
            supervisionQuestion: supervisionQuestion ? '1' : null,
            parentPeerSupportQuestion: parentPeerSupportQuestion ? '1' : null,
            youthPeerSupportQuestion: youthPeerSupportQuestion ? '1' : null,
            trainingGroupUsedQuestion: trainingGroupUsedQuestion ? '1' : null,
        };
    };

    getNavItems = () => {
        if (Fortress.user.acting.role.handle === 'guest') {
            return;
        }
        const navs = AppDefs.navs;
        const permissions =
            Fortress.user.acting.role.handle !== 'uberadmin' &&
            JSON.parse(Fortress.user.acting.role_data.permissions);

        // construct nav items
        const permissionNavs = navs[0].nav.filter(
            n =>
                (permissions && permissions.includes(n.handle)) ||
                Fortress.user.acting.role.handle === 'uberadmin'
        );
        return [{ ...navs, nav: permissionNavs }];
    };

    render() {
        const { groupPawn, showBanner } = this.state;
        const navs = this.getNavItems();
        return (
            <div className={style.body}>
                <div className={style.content}>
                    <NavBar className={this.props.className} />
                    {Fortress.auth() && (
                        <SecondaryNav
                            isInverse
                            className={this.props.className}
                            navs={navs}
                            routes={AppDefs.routes}
                        />
                    )}
                    {showBanner && groupPawn && (
                        <Modal
                            trigger={
                                <ActionBanner
                                    text="Click here to take the yearly group member survey!"
                                    actionText="Take Survey"
                                />
                            }
                            title="Yearly Group Survey"
                            beforeClose={this.checkComplete}
                        >
                            <div className={style.fixContainer}>
                                <DeploymentPlayer
                                    deploymentHandle={deploymentHandle}
                                    pawnId={groupPawn.pawnId}
                                    pawnHash={groupPawn.pawnHash}
                                    flowProps={{
                                        hidePlayButton: true,
                                        isPrintMode: false,
                                        variables: this.getFlowPropsVars(),
                                    }}
                                />{' '}
                            </div>
                        </Modal>
                    )}
                    <div className={this.props.className}>{this.props.children}</div>
                </div>
                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}
