import React from 'react';
import PropTypes from 'prop-types';
import { Player } from 'cccisd-laravel-flow';

const AppDefs = window.cccisd.appDefs;

class SurveyPreview extends React.Component {
    static propTypes = {
        match: PropTypes.object,
    };

    static defaultProps = {};

    state = {};

    render() {
        if (!AppDefs.flow.previewFlows.includes(+this.props.match.params.flowId)) {
            return <div>Survey not found or not available for preview.</div>;
        }

        return <Player flowId={+this.props.match.params.flowId} isPreviewMode hidePlayButton />;
    }
}

export default SurveyPreview;
