import React from 'react';
import PropTypes from 'prop-types';
import { client as apollo } from 'cccisd-apollo';
import { NotFound } from 'cccisd-laravel-boilerplate';
import Loader from 'cccisd-loader';
import { DeploymentEvaluator } from 'cccisd-laravel-assignment';
import toolsQuery from '../../graphql/toolsQuery.graphql';
import moment from 'moment';
import Html from 'cccisd-wysiwyg';
import style from './style.css';

export default class ToolSurvey extends React.Component {
    static propTypes = {
        /** The layout to use for the 404 page */
        errorLayout: PropTypes.func,

        /* From redux-router */
        // match: PropTypes.object.isRequired,

        /* deployment hash */
        deploymentHash: PropTypes.string,
        shortHash: PropTypes.string,

        /** Should we skip saving everything and not run any pre/postFlowRoutes? */
        isPreviewMode: PropTypes.bool,

        /* From redux-router */
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    };

    static defaultProps = {
        errorLayout: null,
    };

    state = {
        isLoading: true,
        show404: false,
    };

    componentDidMount() {
        this.evaluateDeployment();
    }

    /**
     * Get the deployment handle and pawn data, which is needed to route
     * to the deployment for this pawn. It also retrieves pawn data
     * which is used to pre populate data in the flow
     */
    evaluateDeployment = async () => {
        const { match } = this.props;
        const deploymentHash = match.params.deploymentHash;
        const shortHash = match.params.shortHash;

        if (!deploymentHash || !shortHash) {
            return this.setState({ show404: true, isLoading: false });
        }

        try {
            const result = await apollo.query({
                query: toolsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentHash,
                    shortHash,
                },
            });

            if (!result.data.flows.deployment.hash) {
                this.setState({ show404: true, isLoading: false });
            }

            if (!result.data.flows.deployment.isOpen) {
                this.setState({ isClosed: true });
            }

            await this.setState({
                deployment: result.data.flows.deployment,
                deploymentId: result.data.flows.deployment.deploymentId,
                youth: result.data.roles.youth,
            });
        } catch (error) {
            this.setState({ show404: true });
        }

        await this.setState({ isLoading: false });
    };

    getDays = date => {
        // use this helper instead of moment.js to reduce memory
        return date.substring(0, 4) * 365 + date.substring(5, 7) * 30 + date.substring(8, 9) * 1;
    };

    getFlowPropsVars = () => {
        const { youth } = this.state;

        // determine age of youth
        const now = moment();
        const nowStr = now.format('YYYY-MM-DD');
        const daysOfNow = this.getDays(nowStr);

        const dateOfBirth = youth.fields.dateOfBirth;
        const ageAtEnrollment = youth.fields.ageAtEnrollment;
        const startDate = youth.fields.startDate;
        let youthAge = 'Unknown';

        // determine age if DOB availible
        if (dateOfBirth) {
            youthAge = Math.floor((daysOfNow - this.getDays(dateOfBirth)) / 365);
        }

        // infer age based on ageAtEnrollment
        if (!dateOfBirth && ageAtEnrollment) {
            youthAge = parseInt(ageAtEnrollment, 10) + Math.floor((daysOfNow - this.getDays(startDate)) / 365);
        }

        return {
            siteName: youth.parentGroup.site.group.label,
            wrapId: youth.fields.wrapId,
            youthAge: youthAge ? youthAge : 'Unknown',
            firstName: youth.fields.firstName !== null ? youth.fields.firstName : ' ',
            lastName: youth.fields.lastName !== null ? youth.fields.lastName : ' ',
            careCoordinator: youth.careCoordinatorNameEmail,
            hideCareCoordinator: true,
        };
    };

    render() {
        const { isLoading, show404, isClosed, deploymentId, youth, deployment } = this.state;
        const { errorLayout } = this.props;
        let ErrorLayout = errorLayout;

        if (isLoading) {
            return <Loader loading />;
        }

        if (show404) {
            if (errorLayout) {
                return (
                    <ErrorLayout>
                        <NotFound />
                    </ErrorLayout>
                );
            }
            return <NotFound />;
        }

        if (isClosed) {
            return (
                <ErrorLayout>
                    <span className={style.contentWrapper}>
                        <Html content={deployment.settings.closedMessage || ' '} />
                    </span>
                </ErrorLayout>
            );
        }

        let componentProps = {
            ...this.props,
        };

        const youthProps = {
            deploymentId,
            pawnHash: youth.pawn.pawnHash,
            pawnId: youth.pawn.pawnId,
            flowProps: {
                hidePlayButton: true,
                isPrintMode: false,
                variables: this.getFlowPropsVars(),
            },
        };

        if (youth.pawn.pawnId !== null) {
            componentProps = { ...componentProps, ...youthProps };
        }

        // Custom route but calling default evaluator with flow props
        return (
            <div className={style.fixContainer}>
                <DeploymentEvaluator {...componentProps} />
            </div>
        );
    }
}
