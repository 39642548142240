import React from 'react';
import PropTypes from 'prop-types';
import CccisdToggle from 'cccisd-toggle';
import { reduxForm } from 'redux-form';
import { domOnlyProps, TextBlock, FieldBlock } from 'cccisd-redux-form-helpers';
import { client, gql } from 'cccisd-apollo';
import Table from 'cccisd-table';
import style from './style.css';
import flatten from 'flat';
import ClickButton from 'cccisd-click-button';
import InfoLabel from '../InfoLabel';
import { UserCheck } from 'cccisd-laravel-nexus';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import notification from 'cccisd-notification';

const fields = [
    'firstName',
    'lastName',
    'email',
    'adminId',
    'group',
    'adminLabel',
    'permissions',
    'supervisor',
    'existingUser',
    'available',
    'toolPerms',
];

const isEmail = email => {
    return /\S+@\S+\.\S+/.test(email);
};

const adminLabels = window.cccisd.appDefs.pawn.fields.filter(
    field => field.handle === 'adminLabel'
)[0].values;

const validate = values => {
    const errors = {};
    if (!values.available) {
        errors.email = 'User already exists in this group';
    }
    if (!values.firstName) {
        errors.firstName = 'Name is required';
    }
    if (!values.lastName) {
        errors.lastName = 'Name is required';
    }
    if (!values.email) {
        errors.email = 'Email is required';
    } else if (!isEmail(values.email)) {
        errors.email = 'Please enter a valid email';
    }
    if (!values.adminLabel) {
        errors.adminLabel = 'Admin label is required';
    }
    if (!values.permissions) {
        errors.permissions = 'User must have at least one permission';
    } else if (values.permissions.length === 0) {
        errors.permissions = 'User must have at least one permission';
    }
    if (!values.group) {
        errors.group = 'Please select a group';
    }
    return errors;
};

class AdminForm extends React.Component {
    static propTypes = {
        settings: PropTypes.object,
        fields: PropTypes.object,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        operation: PropTypes.string,
    };

    state = {
        groups: [],
        noticeText: '',
        noticeLevel: 'success',
    };

    componentDidUpdate = prevProps => {
        const { permissions, adminLabel } = this.props.fields;
        // if admin label has changed
        if (adminLabel.value && adminLabel.value !== prevProps.fields.adminLabel.value) {
            // set the default values
            permissions.onChange(
                this.props.settings.defaultPermissions[
                    adminLabels.find(label => label.value === adminLabel.value).value
                ]
                    .filter(perm => perm.access)
                    .map(perm => perm.value)
            );
        }
    };

    componentDidMount = () => {
        this.getGroups();
        this.props.fields.available.onChange(true);
        // if toolperms not set -> init with array
        if (!this.props.fields.toolPerms.value) {
            this.props.fields.toolPerms.onChange([]);
        }
    };

    getGroups = async () => {
        const { settings } = this.props;

        const response = await client.query({
            query: gql`
                ${this.props.settings.groupQuery}
            `,
        });

        this.setState({
            groups: response.data.groups[settings.listKey].map(item => flatten(item)),
        });
    };

    shouldShowSelectGroup = () => {
        const { settings } = this.props;

        // group users adding another group user at the same level shouldn't have to click
        // the only option available (user is added to the same group)
        if (window.cccisd.fortress.user.acting.data_type !== 'uberadmin') {
            const userLevel = window.cccisd.fortress.user.acting.data_type;
            if (userLevel === settings.adminType && this.state.groups.length === 1) {
                if (!this.props.fields.group.value) {
                    this.props.fields.group.onChange(this.state.groups[0]['group.groupId']);
                }
                return false;
            }
        }

        return true;
    };

    onPermissionsChange = permValue => {
        const { permissions } = this.props.fields;
        permissions.onChange(
            permissions.value.includes(permValue)
                ? permissions.value.filter(handle => handle !== permValue)
                : [...permissions.value, permValue]
        );
    };

    onToolChange = permValue => {
        const { toolPerms } = this.props.fields;
        toolPerms.onChange(
            toolPerms.value.includes(permValue)
                ? toolPerms.value.filter(handle => handle !== permValue)
                : [...toolPerms.value, permValue]
        );
    };

    userEditingSelf = () => {
        const { username } = window.cccisd.fortress.user.acting.user;
        const formUsername = this.props.fields.email.value;
        return username === formUsername;
    };

    /**
     * When an email is entered into the email field,
     * check if the username exists already and is available
     * to use with this username/group combination.
     *
     * Also check when the group is selected or changed.
     */
    userCheck = async newGroup => {
        const checkUserDelay = 1000;
        const {
            fields: { email, group },
            settings,
        } = this.props;

        if (!email.value) {
            return;
        }

        // needed on submit
        this.props.fields.existingUser.onChange(false);
        this.props.fields.available.onChange(true);

        let checkUserData = {
            username: email.value,
            group: _isEmpty(newGroup) ? newGroup : group.value,
            role: settings.adminType,
        };

        let userCheckResponse = await UserCheck(checkUserData, checkUserDelay);

        if (userCheckResponse.available) {
            // show username is available
            this.setState({ noticeText: 'Username is available!', noticeLevel: 'success' });
            // check if existing user
            // if yes, pre populate first and last name
            if (userCheckResponse.user) {
                this.props.fields.existingUser.onChange(true);

                this.setState({
                    noticeText: 'User already exists in another group, filling details',
                    noticeLevel: 'info',
                });
                this.props.fields.firstName.onChange(userCheckResponse.user.first_name);
                this.props.fields.lastName.onChange(userCheckResponse.user.last_name);
            }
        } else {
            this.props.fields.existingUser.onChange(true);
            this.props.fields.available.onChange(false);
            // user is not available
            // maybe same group?
            // show error on form
            this.setState({
                noticeText: 'User already exists in this group',
                noticeLevel: 'danger',
            });
        }
    };

    showNotice = (text, level) => {
        if (this.state.noticeText !== '') {
            return <div className={classnames(style.notice, style[level])}>{text}</div>;
        }
    };

    render() {
        const {
            fields: {
                firstName,
                lastName,
                email,
                adminId,
                group,
                adminLabel,
                permissions,
                supervisor,
                toolPerms,
            },
            settings,
            handleSubmit,
            submitting,
            operation,
        } = this.props;
        const selectedGroup =
            group.value && this.state.groups.find(g => g['group.groupId'] === group.value);

        const toolPermissions = selectedGroup
            ? [
                  { value: 'wfi', label: 'WFI-EZ' },
                  { value: 'dart', label: 'DART' },
                  { value: 'tom', label: 'TOM 2.0' },
              ].filter(tool => {
                  if (tool.value === 'wfi' && !selectedGroup['fields.wfiLicense']) {
                      return false;
                  }
                  if (tool.value === 'dart' && !selectedGroup['fields.dartLicense']) {
                      return false;
                  }
                  if (tool.value === 'tom' && !selectedGroup['fields.tomLicense']) {
                      return false;
                  }
                  return true;
              })
            : [];

        const perms = this.props.settings.defaultPermissions[
            adminLabel.value
                ? adminLabels.find(label => label.value === adminLabel.value).value
                : 'admin'
        ];
        return (
            <form onSubmit={handleSubmit}>
                {this.shouldShowSelectGroup() && (
                    <FieldBlock field={group} label={<InfoLabel label="Select Group" />}>
                        <Table
                            rowKey="group.groupId"
                            columns={settings.columns}
                            data={this.state.groups}
                            onRowClick={row => {
                                group.onChange(row['group.groupId']);
                                this.userCheck(row['group.groupId']);
                            }}
                            showRowClickable
                            perPage={10}
                            setRowClass={row =>
                                row['group.groupId'] === group.value && style.selectedRow
                            }
                        />
                    </FieldBlock>
                )}
                {this.showNotice(this.state.noticeText, this.state.noticeLevel)}
                <TextBlock
                    field={email}
                    label={<InfoLabel label="Email" />}
                    disabled={operation === 'edit' ? true : false}
                    autoFocus={operation === 'create' ? true : false}
                    onBlur={this.userCheck}
                />
                <TextBlock
                    field={firstName}
                    label={<InfoLabel label="First Name" />}
                    autoFocus={operation === 'edit' ? true : false}
                />
                <TextBlock field={lastName} label={<InfoLabel label="Last Name" />} />
                <TextBlock field={adminId} label={<InfoLabel label="Admin ID" optional />} />

                <div className={style.alignItems}>
                    <div>
                        <FieldBlock field={adminLabel} label={<InfoLabel label="Admin Label" />}>
                            <select
                                className="form-control"
                                {...domOnlyProps(adminLabel)}
                                style={{ width: 'auto' }}
                            >
                                <option value="">-- Select label --</option>
                                {adminLabels.map(label => (
                                    <option key={label.value} value={label.value}>
                                        {label.name}
                                    </option>
                                ))}
                            </select>
                        </FieldBlock>
                        <div className={!group.value && style.disabled}>
                            {toolPermissions.length > 0 && (
                                <FieldBlock
                                    field={toolPerms}
                                    label={
                                        <InfoLabel
                                            label="Tool Access"
                                            optional
                                            tooltip="If none selected, the user will assume tool access of the group"
                                        />
                                    }
                                >
                                    {toolPermissions.map(perm => {
                                        return (
                                            <div className="checkbox" key={perm.value}>
                                                <CccisdToggle
                                                    value={
                                                        toolPerms.value &&
                                                        toolPerms.value.includes(perm.value)
                                                    }
                                                    onChange={
                                                        group.value
                                                            ? this.onToolChange.bind(
                                                                  this,
                                                                  perm.value
                                                              )
                                                            : () =>
                                                                  notification({
                                                                      message:
                                                                          'Please select a group',
                                                                      type: 'danger',
                                                                  })
                                                    }
                                                    label={perm.label}
                                                />
                                            </div>
                                        );
                                    })}
                                </FieldBlock>
                            )}
                        </div>
                    </div>
                    <div className={!adminLabel.value && style.disabled}>
                        <FieldBlock field={permissions} label={<InfoLabel label="Permissions" />}>
                            {perms.map(perm => {
                                // if reports
                                if (
                                    perm.value === 'viewData' &&
                                    permissions.value &&
                                    !permissions.value.includes('reports')
                                ) {
                                    if (permissions.value.includes('viewData')) {
                                        this.onPermissionsChange('viewData');
                                    }
                                    return (
                                        <div className="checkbox" key={perm.value}>
                                            <CccisdToggle
                                                value={
                                                    permissions.value &&
                                                    permissions.value.includes(perm.value)
                                                }
                                                disabled
                                                label={
                                                    <InfoLabel
                                                        label={perm.label}
                                                        tooltip="Must also have Reports permission"
                                                    />
                                                }
                                            />
                                        </div>
                                    );
                                }
                                if (this.userEditingSelf() && perm.label === 'Manage') {
                                    return null;
                                }
                                return (
                                    <div className="checkbox" key={perm.value}>
                                        <CccisdToggle
                                            value={
                                                permissions.value &&
                                                permissions.value.includes(perm.value)
                                            }
                                            onChange={
                                                adminLabel.value
                                                    ? this.onPermissionsChange.bind(
                                                          this,
                                                          perm.value
                                                      )
                                                    : () =>
                                                          notification({
                                                              message:
                                                                  'Please select an admin label',
                                                              type: 'danger',
                                                          })
                                            }
                                            label={perm.label}
                                        />
                                    </div>
                                );
                            })}
                        </FieldBlock>
                    </div>
                </div>
                <TextBlock field={supervisor} label={<InfoLabel label="Supervisor" optional />} />
                <div className={style.justifyRight}>
                    <ClickButton
                        title="Submit"
                        onClick={handleSubmit}
                        isLoading={submitting}
                        className="btn btn-success"
                    />
                </div>
            </form>
        );
    }
}
export default reduxForm({
    form: 'adminForm',
    fields,
    validate,
})(AdminForm);
