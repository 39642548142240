import React from 'react';
import { Export } from 'cccisd-laravel-assignment';

export default class ExportData extends React.Component {
    render() {
        return (
            <div className="container">
                <h1>Export Data</h1>
                <Export projectId={2} />
            </div>
        );
    }
}
