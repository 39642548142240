import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import NavBar from 'js/components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
var AppDefs = window.cccisd.appDefs;

export default class FluidLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return (
            <div>
                <NavBar className="container-fluid" />
                <SecondaryNav
                    className="container-fluid"
                    navs={AppDefs.navs}
                    routes={AppDefs.routes}
                />
                <div className="container-fluid">{this.props.children}</div>
            </div>
        );
    }
}
