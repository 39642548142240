import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'cccisd-modal';
import classnames from 'classnames';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import IconSelect from 'cccisd-icons/stack-check';
import IconCheck from 'cccisd-icons/checkbox-checked2';
import IconUncheck from 'cccisd-icons/checkbox-unchecked2';
import IconHalfcheck from 'cccisd-icons/checkbox-partial2';
import IconExpandClose from 'cccisd-icons/arrow-right';
import IconExpandOpen from 'cccisd-icons/arrow-down';
import IconParentClose from 'cccisd-icons/folder2';
import IconParrentOpen from 'cccisd-icons/folder-open';
import IconLeaf from 'cccisd-icons/earth2';
import { connect } from 'react-redux';
import { setChecked, setFieldChecked, setExpanded, setLoaded } from '../../reducers/sites.js';
import { getSiteTree } from '../../selectors/sites.js';
import style from './style.css';
import Button from 'cccisd-click-button';
import hash from 'object-hash';

const previousSitesKey = `${window.cccisd.fortress.user.acting.respondent_hash}-sites`;

const getInitialSites = isSingleSelect => {
    const prevSites =
        localStorage.getItem(previousSitesKey) &&
        localStorage
            .getItem(previousSitesKey)
            .split(',')
            .map(x => Number(x));
    if (isSingleSelect && prevSites?.length > 0) {
        return [prevSites[0]];
    }
    if (prevSites && prevSites?.length > 0) {
        return prevSites;
    }
    return [];
};

class SiteSelector extends React.Component {
    static propTypes = {
        page: PropTypes.string,
        isFormField: PropTypes.bool,
        onChange: PropTypes.func,
        singleSelect: PropTypes.bool,
        // from redux
        groups: PropTypes.array,
        siteTree: PropTypes.array,
        loaded: PropTypes.bool,
        setLoaded: PropTypes.func,
        setChecked: PropTypes.func,
        setExpanded: PropTypes.func,
        checked: PropTypes.array,
        fieldChecked: PropTypes.array,
        setFieldChecked: PropTypes.func,
        expanded: PropTypes.array,
        customChecked: PropTypes.bool,
        license: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.modal = React.createRef();
    }

    state = {
        checked: getInitialSites(this.props.singleSelect),
    };

    componentDidMount = async () => {
        if (this.state.checked.length === 0) {
            this.checkAllSites();
            this.props.setLoaded(true);
        }
        if (!this.props.singleSelect && !this.props.isFormField && this.state.checked === []) {
            this.props.setLoaded(false);
            this.checkAllSites();
        }
    };

    componentDidUpdate = prevProps => {
        // if first load, set all sites
        if (this.props.page === prevProps.page && !this.props.loaded) {
            this.props.setLoaded(true);
        }

        if (
            this.props.groups.length !== prevProps.groups.length ||
            hash(this.props.siteTree) !== hash(prevProps.siteTree) ||
            this.props.singleSelect !== prevProps.singleSelect ||
            this.props.page !== prevProps.page
        ) {
            if (!this.props.singleSelect && this.state.checked.length === 0) {
                this.checkAllSites();
                this.props.setLoaded(true);
            }
        }
        if (this.props.isFormField && this.props.fieldChecked.length === 0) {
            if (this.isSiteAdmin()) {
                const mySite = [window.cccisd.fortress.user.acting.group.id];
                this.handleCheck(mySite);
                this.props.setFieldChecked(mySite);
                this.props.onChange(mySite);
            }
        }
        if (this.props.loaded !== prevProps.loaded) {
            this.setSiteSelection();
        }
        if (this.props.singleSelect || this.props.isFormField) {
            if (prevProps.fieldChecked !== this.props.fieldChecked) {
                this.setState({ checked: this.props.fieldChecked });
            }
        }
    };

    expandAll = () => {
        const parents = this.props.groups
            .filter(group => group.group.groupType !== 'site')
            .map(site => site.group.groupId);
        this.props.setExpanded(parents);
    };

    collapseAll = () => {
        this.props.setExpanded([]);
    };

    handleCheck = checked => {
        checked = checked.map(item => Number(item));
        if (this.props.singleSelect) {
            checked = checked.filter(id => !this.state.checked.includes(id));
        }
        this.setState({ checked });
    };

    checkAllSites = async () => {
        const license = group => (this.props.license ? group.fields[this.props.license] : true);
        const sites = this.props.groups
            .filter(group => group.group.groupType === 'site' && license(group))
            .map(site => site.group.groupId);
        await this.setState({ checked: sites });
        this.setSiteSelection();
    };

    uncheckAllSites = () => {
        this.setState({ checked: [] });
    };

    setSiteSelection = () => {
        const { checked } = this.state;
        if (this.props.isFormField) {
            this.props.setFieldChecked(checked);
            this.props.onChange(checked);
        } else {
            localStorage.setItem(previousSitesKey, checked);
            this.props.setChecked(checked);
        }
    };

    // /////////////////////////

    closeModal = () => {
        this.modal.current.close();
    };

    getLabel = () => {
        if (this.props.singleSelect) {
            return 'Select Site';
        }

        return `Selected Sites (${this.state.checked.length})`;
    };

    isSiteAdmin = () => {
        return window.cccisd.fortress.user.acting.data_type === 'siteAdmin';
    };

    render() {
        // if there's only one site availible, show its name instead of the sitepicker
        if (this.isSiteAdmin()) {
            return (
                <h2 className={style.siteNameLabel}>
                    {window.cccisd.fortress.user.acting.group.label}
                </h2>
            );
        }
        return (
            <div className={style.flex}>
                <Modal
                    ref={this.modal}
                    size="large"
                    trigger={
                        <button
                            type="button"
                            className={classnames('btn', 'btn-primary', style.btn)}
                        >
                            <IconSelect spaceRight />
                            {this.getLabel()}
                        </button>
                    }
                    title={this.getLabel()}
                    beforeClose={this.setSiteSelection}
                >
                    <div className={style.flex}>
                        <CheckboxTree
                            onlyLeafCheckboxes={this.props.singleSelect}
                            nodes={this.props.loaded ? this.props.siteTree : {}}
                            checked={this.state.checked}
                            expanded={this.props.expanded}
                            onCheck={checked => this.handleCheck(checked)}
                            onExpand={expanded => this.props.setExpanded(expanded)}
                            icons={{
                                check: <IconCheck />,
                                uncheck: <IconUncheck />,
                                halfCheck: <IconHalfcheck />,
                                expandClose: <IconExpandClose />,
                                expandOpen: <IconExpandOpen />,
                                expandAll: <IconExpandOpen />,
                                collapseAll: <IconExpandClose />,
                                parentClose: <IconParentClose />,
                                parentOpen: <IconParrentOpen />,
                                leaf: <IconLeaf />,
                            }}
                        />
                        <div className={style.buttonBox}>
                            {!this.props.singleSelect && (
                                <div className={style.flex}>
                                    <Button
                                        className={classnames('btn', 'btn-default', style.button)}
                                        title="Select All"
                                        onClick={this.checkAllSites}
                                    />
                                    <Button
                                        className={classnames('btn', 'btn-default', style.button)}
                                        title="Deselect All"
                                        onClick={this.uncheckAllSites}
                                    />
                                </div>
                            )}
                            <div className={style.flex}>
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Expand All"
                                    onClick={this.expandAll}
                                />
                                <Button
                                    className={classnames('btn', 'btn-default', style.button)}
                                    title="Collapse All"
                                    onClick={this.collapseAll}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.modalFooter}>
                        <Button
                            title="Done"
                            className="btn btn-primary"
                            onClick={this.closeModal}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    siteTree: getSiteTree(state.app.sites),
    loaded: state.app.sites.loaded,
    checked: state.app.sites.checked,
    expanded: state.app.sites.expanded,
    license: state.app.sites.license,
    customChecked: state.app.sites.customChecked,
    fieldChecked: state.app.sites.fieldChecked,
});
export default connect(mapStateToProps, {
    setChecked,
    setExpanded,
    setFieldChecked,
    setLoaded,
})(SiteSelector);
