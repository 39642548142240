import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import { setTabs } from '../../reducers/data.js';

// Custom tabs component needed for advanced styles
class Tabs extends React.Component {
    static propTypes = {
        tabList: PropTypes.array,
        slim: PropTypes.bool,
        level: PropTypes.number, // for nested tabs top-most tabs is 0, then 1, 2, ...n
        tabId: PropTypes.string,
        onClick: PropTypes.func,
        initTabName: PropTypes.string,
        // from redux
        tabs: PropTypes.array,
        setTabs: PropTypes.func,
    };

    state = {
        activeTabName: '',
    };

    componentDidMount = () => {
        const { tabs } = this.props;
        let initTab = this.props.initTabName;

        if (tabs.length > 0) {
            const myTab = tabs.find(tab => tab.tabId === this.props.tabId && tab.level === this.getLevel());
            if (myTab) {
                initTab = myTab.name;
            }
        }
        if (!initTab) {
            initTab = this.getFirstTab();
        }
        this.setActiveTab(initTab);
    };

    getFirstTab = () => {
        return this.props.tabList[0].name;
    };

    getLevel = () => {
        return this.props.level ? this.props.level : 0;
    };

    setActiveTab = (activeTabName, isInit = false) => {
        let newActiveTab = activeTabName;
        // if name in tabList
        if (!this.props.tabList.find(tab => tab.name === activeTabName)) {
            newActiveTab = this.props.tabList[0].name;
        }
        this.setState({ activeTabName: newActiveTab });
        this.props.setTabs({ level: this.getLevel(), name: newActiveTab, tabId: this.props.tabId });
        this.props.onClick && this.props.onClick(newActiveTab, isInit);
    };

    render() {
        const { activeTabName } = this.state;
        if (activeTabName) {
            return (
                <div>
                    <ul
                        className={classnames('nav', 'nav-tabs', style.navTabs)}
                        style={this.props.slim && { marginTop: '1em' }}
                    >
                        {this.props.tabList.map(tab => (
                            <li
                                key={tab.name}
                                role="presentation"
                                className={`nav-link ${activeTabName === tab.name ? 'active ' + style.active : ''}`}
                                onClick={() => this.setActiveTab(tab.name)}
                            >
                                <a
                                    className={style.anchor}
                                    style={
                                        this.props.slim && {
                                            paddingTop: '0em',
                                            paddingBottom: '0em',
                                        }
                                    }
                                    href="#"
                                >
                                    {tab.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className={style.content} style={this.props.slim && { marginTop: '0em' }}>
                        {this.props.tabList.find(tab => tab.name === activeTabName).content}
                    </div>
                </div>
            );
        }
        return <Loader />;
    }
}

const mapStateToProps = state => ({
    tabs: state.app.data.tabs,
});

export default connect(mapStateToProps, { setTabs })(Tabs);
