import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';

export default class ActionBanner extends React.Component {
    static propTypes = {
        text: PropTypes.string,
    };

    render() {
        return (
            <div className={style.banner}>
                <div className="container">
                    <div className={style.flexIt}>
                        <div>{this.props.text}</div>
                    </div>
                </div>
            </div>
        );
    }
}
