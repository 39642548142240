import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import style from './style.css';
import classnames from 'classnames';

export default class InfoLabel extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        tooltip: PropTypes.string,
        placement: PropTypes.string,
        optional: PropTypes.bool,
        color: PropTypes.string,
        hideDecorator: PropTypes.bool,
    };

    renderDecorator = () => {
        if (this.props.optional) {
            return <div className={style.optional}>- Optional</div>;
        }
        if (this.props.hideDecorator) {
            return;
        }
        return <span className={style.required}>*</span>;
    };

    render() {
        const { label, tooltip, placement } = this.props;
        return (
            <span {...this.props}>
                <div className={style.label}>{label}</div> {this.renderDecorator()}
                {tooltip && (
                    <Tooltip title={tooltip} placement={placement || 'top'}>
                        <span
                            style={{ color: this.props.color }}
                            className={classnames('text-primary', style.tooltip)}
                        >
                            <IconInfo />
                        </span>
                    </Tooltip>
                )}
            </span>
        );
    }
}
