import React from 'react';
import style from './style.css';
import Tabs from '../../components/Tabs';
import DataTabs from './DataTabs';

const appdefs = window.cccisd.appDefs.app;

export default class ViewData extends React.Component {
    // state = {
    //     isLoading: true,
    // };

    getDataTabs = () => {
        const dataTabs = [
            {
                name: 'wfiCareCoord',
                title: 'WFI-EZ Care Coordinator',
                license: 'wfi',
                content: (
                    <DataTabs
                        key="wfiCC"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiCareCoordAssignmentId}
                        // deploymentWhitelist={this.state.wfiCCDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiCaregiver',
                title: 'WFI-EZ Caregiver',
                license: 'wfi',
                content: (
                    <DataTabs
                        key="wfiCG"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiCaregiverAssignmentId}
                        // deploymentWhitelist={this.state.wfiCGDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiTeamMember',
                title: 'WFI-EZ Team Member',
                license: 'wfi',
                content: (
                    <DataTabs
                        key="wfiTM"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiTeamMemberAssignmentId}
                        // deploymentWhitelist={this.state.wfiTMDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'wfiYouth',
                title: 'WFI-EZ Youth',
                license: 'wfi',
                content: (
                    <DataTabs
                        key="wfiY"
                        projectId={appdefs.wfiProjectId}
                        assignmentId={appdefs.wfiYouthAssignmentId}
                        // deploymentWhitelist={this.state.wfiYTDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'tom',
                title: 'TOM 2.0',
                license: 'tom',
                content: (
                    <DataTabs
                        key="tom"
                        projectId={appdefs.tomProjectId}
                        assignmentId={appdefs.tomAssignmentId}
                        // deploymentWhitelist={this.state.tomDeploymentWhitelist}
                    />
                ),
            },
            {
                name: 'dart',
                title: 'DART',
                license: 'dart',
                content: (
                    <DataTabs
                        key="dart"
                        projectId={appdefs.dartProjectId}
                        assignmentId={appdefs.dartAssignmentId}
                        // deploymentWhitelist={this.state.dartDeploymentWhitelist}
                    />
                ),
            },
        ];

        return dataTabs;
    };

    render() {
        // if (this.state.isLoading) {
        //     return <Loader loading />;
        // }
        return (
            <div className={style.pageMargin}>
                <br />
                <Tabs tabId="viewData" tabList={this.getDataTabs()} />
            </div>
        );
    }
}
